<template>
  <div v-if="classAttributes">
    <ValidationObserver ref="attributesInput">
      <b-table
        ref="table"
        striped
        :fields="fields"
        :items="tableAttributes"
        stacked="md"
        small
        class="mt-4"
        bordered
        sort-icon-left
        v-if="classAttributes.length > 0"
      >
        <template v-slot:cell(name)="data">{{ data.item.name }}</template>
        <template v-slot:cell(classValue)="data"
          ><div :class="valueCheckClass(data.item.value, data.item.classValue)">
            {{ data.item.classValue }}
          </div></template
        >
        <template v-slot:cell(value)="data">
          <div v-if="editedAttribute.tenantAttributeId == data.item.id">
            <div v-if="getAttributeValueType(data.item.id) == 1">
              <select
                class="form-control p-0"
                id="singleSelect"
                v-model="editedAttribute.attributeSelectedOptionIds[0]"
              >
                <option
                  v-for="option in getAttributeOptions(data.item.id)"
                  :key="option.id"
                  :value="option.id"
                  >{{ option.value }}</option
                >
              </select>
            </div>
            <div v-if="getAttributeValueType(data.item.id) == 2">
              <ValidationProvider
                name="Value"
                rules="required|exclusiveNoValue"
                v-slot="{ errors, ariaInput, ariaMsg }"
              >
                <multiselect
                  label="value"
                  track-by="id"
                  :multiple="true"
                  id="optionValues"
                  :clear-on-select="false"
                  :preserve-search="true"
                  open-direction="bottom"
                  placeholder="Select Option(s)"
                  :options="getAttributeOptions(data.item.id)"
                  v-model="multiSelectedOptions"
                >
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="multiselect__tag" :key="option.id">
                      <span>{{ option.value }}</span>
                      <i
                        tabindex="1"
                        @keypress.enter.prevent="remove(option)"
                        @mousedown.prevent="remove(option)"
                        class="multiselect__tag-icon"
                      ></i>
                    </span>
                  </template>
                </multiselect>
                <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                  <li
                    v-for="(error, index) in errors"
                    :key="index"
                    class="error"
                    v-bind="ariaMsg"
                  >
                    {{ error }}
                  </li>
                </ul>
              </ValidationProvider>
            </div>
            <div v-if="getAttributeValueType(data.item.id) == 3">
              <select
                class="form-control p-0"
                id="yesNo"
                v-model="editedAttribute.value"
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div v-else-if="getAttributeValueType(data.item.id) == 4">
              <TextInput
                rules="required|numeric"
                name="Value"
                id="value"
                placeholder="Enter number"
                :hideLabel="true"
                v-model="editedAttribute.value"
              />
            </div>
            <div v-else-if="getAttributeValueType(data.item.id) == 5">
              <TextInput
                rules="required|alpha_num_special"
                name="Value"
                id="value"
                placeholder="Enter a value"
                :hideLabel="true"
                v-model="editedAttribute.value"
              />
            </div>
            <div v-else-if="getAttributeValueType(data.item.id) == 6">
              <TextInput
                rules="required|time"
                name="Value"
                id="value"
                placeholder="Enter a time value"
                :hideLabel="true"
                v-model="editedAttribute.value"
              />
            </div>
          </div>

          <div v-else>
            <div v-if="data.item.hasNoSpotValue">[No Value]</div>
            <div v-else>{{ data.item.value }}</div>
          </div>
        </template>

        <template v-slot:cell(id)="data">
          <button
            class="btn btn-primary btn-sm mx-1"
            @click="remove(data.item.id)"
            v-if="
              editedAttribute.tenantAttributeId != data.item.id &&
                !view &&
                config.showDeleteButton
            "
            type="button"
            :disabled="viewOnly"
          >
            Delete
          </button>

          <button
            class="btn btn-primary btn-sm mx-1"
            @click="edit(data.item.id)"
            v-if="editedAttribute.tenantAttributeId != data.item.id && !view"
            type="button"
            :disabled="viewOnly"
          >
            Modify
          </button>
          <button
            class="btn btn-primary btn-sm mx-1"
            @click="save(data.item.id)"
            v-if="editedAttribute.tenantAttributeId == data.item.id"
            type="button"
          >
            Save
          </button>
          <button
            class="btn btn-primary btn-sm mx-1"
            @click="remove(data.item.id)"
            v-if="
              editedAttribute.tenantAttributeId == data.item.id &&
                !view &&
                config.showClearButton
            "
            type="button"
            :disabled="viewOnly"
          >
            Clear
          </button>
          <button
            class="btn btn-cancel btn-sm mx-1"
            @click="cancel(data.item.id)"
            v-if="editedAttribute.tenantAttributeId == data.item.id"
            type="button"
          >
            Cancel
          </button>
        </template>
      </b-table>
      <div v-if="classAttributes.length == 0" class="pt-2">
        No attributes are associated with this product class.
      </div>
      <hr />
      <div class="row" v-if="addMode">
        <div class="col-5">
          <select
            class="form-control form-control-lg"
            id="addAttribute"
            v-model="newAttribute.id"
          >
            <option value>Select Attribute...</option>
            <option
              v-for="attribute in unusedAttributes"
              :key="attribute.id"
              :value="attribute.id"
              >{{ attribute.globalAttribute.name }}</option
            >
          </select>
        </div>
        <div class="col-4">
          <div v-if="newAttribute">
            <div v-if="getAttributeValueType(newAttribute.id) == 1">
              <select
                class="form-control form-control-lg"
                id="singleSelect"
                v-model="newAttribute.selectedOptionId"
              >
                <option value>Please Choose...</option>
                <option
                  v-for="option in getAttributeOptions(newAttribute.id)"
                  :key="option.id"
                  :value="option.id"
                  >{{ option.value }}</option
                >
              </select>
            </div>
            <div v-if="getAttributeValueType(newAttribute.id) == 2">
              <multiselect
                label="value"
                track-by="id"
                :multiple="true"
                id="optionValues"
                :clear-on-select="false"
                :preserve-search="true"
                open-direction="bottom"
                placeholder="Select Option(s)"
                :options="getAttributeOptions(newAttribute.id)"
                v-model="newAttribute.selectedOptionIds"
              >
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="multiselect__tag" :key="option.id">
                    <span>{{ option.value }}</span>
                    <i
                      tabindex="1"
                      @keypress.enter.prevent="remove(option)"
                      @mousedown.prevent="remove(option)"
                      class="multiselect__tag-icon"
                    ></i>
                  </span>
                </template>
              </multiselect>
            </div>
            <div v-if="getAttributeValueType(newAttribute.id) == 3">
              <select
                class="form-control form-control-lg"
                id="yesNo"
                v-model="newAttribute.value"
              >
                <option value>Please Choose...</option>
                <option value="yes">yes</option>
                <option value="no">no</option>
              </select>
            </div>
            <div v-else-if="getAttributeValueType(newAttribute.id) == 4">
              <TextInput
                :rules="
                  `required|numeric|${getRangeValidationString(
                    newAttribute.id
                  )}`
                "
                name="Value"
                id="value"
                placeholder="Enter number"
                :hideLabel="true"
                v-model="newAttribute.value"
              />
            </div>
            <div v-else-if="getAttributeValueType(newAttribute.id) == 5">
              <TextInput
                rules="required|alpha_num"
                name="Value"
                id="value"
                placeholder="Enter a value"
                :hideLabel="true"
                v-model="newAttribute.value"
              />
            </div>
            <div v-else-if="getAttributeValueType(newAttribute.id) == 6">
              <TextInput
                rules="required|time"
                name="Value"
                id="value"
                placeholder="Enter a time value"
                :hideLabel="true"
                v-model="newAttribute.value"
              />
            </div>
          </div>
        </div>
        <div class="col-3">
          <button
            type="button"
            class="btn btn-primary btn-sm mx-1"
            @click="newAttributeAdd"
            v-if="
              newAttribute.value != '' ||
                newAttribute.selectedOptionIds.length > 0 ||
                newAttribute.selectedOptionId > 0
            "
          >
            Add
          </button>

          <button
            class="btn btn-cancel btn-sm mx-1"
            type="button"
            @click="newAttributeCancel"
          >
            Cancel
          </button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <button
            class="btn btn-primary float-left mb-3"
            type="button"
            @click="addAttribute"
            v-if="!addMode && !view && config.showAddNewAttributeButton"
          >
            ADD NEW CLASS ATTRIBUTE
            <i class="fa fa-plus-circle" />
          </button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import TextInput from "@/validation/TextInput.vue";
import Multiselect from "vue-multiselect";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "AdminProductClassAttributes",
  props: {
    classAttributes: { type: Array },
    view: { type: Boolean, default: false },
    allAttributes: { type: Array },
    spotAttributes: { type: Array, default: null },
    mode: { type: String, default: "class" },
    viewOnly: Boolean
  },
  components: {
    TextInput,
    Multiselect,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      attributesModified: [],
      editedAttribute: {},
      multiSelectedOptions: [],
      addMode: false,
      newAttribute: {
        id: "",
        selectedOptionIds: [],
        selectedOptionId: "",
        value: ""
      }
    };
  },
  computed: {
    config() {
      let configSettings = [];
      if (this.mode == "spot") {
        configSettings = {
          showDeleteButton: false,
          showClearButton: true,
          showAddNewAttributeButton: false,
          attributeColumnText: "Spot Attribute Value(s)",
          classAttributeColumnText: "Class AttributeValue(s)",
          mode: "spot"
        };
      } else {
        configSettings = {
          showDeleteButton: true,
          showClearButton: false,
          showAddNewAttributeButton: true,
          attributeColumnText: "Attribute Value(s)",
          classAttributeColumnText: "",
          mode: "class"
        };
      }
      if (this.viewOnly) {
        configSettings.showAddNewAttributeButton = false;
      }

      return configSettings;
    },
    fields() {
      let fields = [
        {
          key: "name",
          sortable: true,
          label: "Attribute Name"
        }
      ];

      if (this.mode == "spot") {
        fields.push({ key: "classValue", label: "Class Attribute Value(s)" });
      }

      fields.push({ key: "value", label: this.config.attributeColumnText });

      if (!this.view) {
        fields.push({ key: "id", label: "Action" });
      }

      return fields;
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    tableAttributes() {
      let fields = [];

      if (
        !this.attributesModified ||
        this.allAttributes.length == 0 ||
        this.newAttribute.id == null
      ) {
        return fields;
      }

      //Combine attribute from attribute list with selected value
      this.classAttributes.forEach(att => {
        const attribute = this.allAttributes.find(
          n => n.id == att.tenantAttributeId
        );

        let field = {
          name: attribute.globalAttribute.name,
          id: attribute.id,
          valueType: attribute.globalAttribute.attributeValueType.id,
          classValue: this.getAttributeFieldValue(att, attribute),
          value: "",
          hasNoSpotValue: false
        };

        const attributeModified = this.attributesModified.find(
          am => am.tenantAttributeId == att.tenantAttributeId
        );

        field.value = this.getAttributeFieldValue(attributeModified, attribute);
        if (attributeModified) {
          field.hasNoSpotValue = attributeModified.hasNoValue;
        }
        fields.push(field);
      });
      return fields;
    },
    unusedAttributes() {
      const classAttributeIds = this.attributesModified.map(
        ca => ca.tenantAttributeId
      );
      return this.allAttributes.filter(a => !classAttributeIds.includes(a.id));
    }
  },
  methods: {
    valueCheckClass(spotValue, classValue) {
      if (spotValue && spotValue != classValue) {
        return "differentValues";
      }
      return "";
    },
    updated() {
      this.$emit("updated", this.attributesModified);
    },

    remove(id) {
      this.attributesModified = this.attributesModified.filter(
        att => att.tenantAttributeId != id
      );
      this.cancel();
      this.updated();
    },
    edit(id) {
      let attribute = this.attributesModified.find(
        att => att.tenantAttributeId == id
      );
      if (!attribute) {
        attribute = this.classAttributes.find(
          att => att.tenantAttributeId == id
        );
      }

      this.editedAttribute = {
        tenantAttributeId: attribute.tenantAttributeId,
        value: attribute.value,
        attributeSelectedOptionIds: []
      };
      this.editedAttribute.attributeSelectedOptionIds = this.editedAttribute.attributeSelectedOptionIds.concat(
        attribute.attributeSelectedOptionIds
      );

      //Set selected options for multi select
      this.multiSelectedOptions = [];

      if (this.getAttributeValueType(id) == 2) {
        const attributeOptions = this.getAttributeOptions(id);
        attribute.attributeSelectedOptionIds.forEach(a => {
          const attValue = attributeOptions.find(n => n.id == a);
          if (attValue) {
            this.multiSelectedOptions.push({
              id: attValue.id,
              value: attValue.value
            });
          }
        });
      }
    },
    cancel() {
      this.editedAttribute = {};
      this.multiSelectedOptions = {};
    },
    async save(id) {
      const typeId = this.getAttributeValueType(id);
      const isValid = await this.$refs.attributesInput.validate();
      if (!isValid) {
        return;
      }
      let attribute = this.attributesModified.find(
        att => id == att.tenantAttributeId
      );
      if (!attribute) {
        attribute = this.clone(
          this.classAttributes.find(att => id == att.tenantAttributeId)
        );
        this.attributesModified.push(attribute);
      }

      attribute.value = this.editedAttribute.value;
      if (this.multiSelectedOptions.length > 0) {
        attribute.attributeSelectedOptionIds = this.multiSelectedOptions.map(
          option => option.id
        );
      } else if (
        this.editedAttribute.attributeSelectedOptionIds.length > 0 &&
        typeId != 2
      ) {
        attribute.attributeSelectedOptionIds = this.editedAttribute.attributeSelectedOptionIds;
      } else {
        attribute.attributeSelectedOptionIds = [];
      }

      const classAttribute = this.classAttributes.find(
        att => id == att.tenantAttributeId
      );

      if (
        this.config.mode === "spot" &&
        classAttribute.attributeSelectedOptionIds.join(",") ==
          attribute.attributeSelectedOptionIds.join(",") &&
        classAttribute.value == attribute.value
      ) {
        //If the spot value is set back to the class attribute value, remove the value from attributesModified
        this.attributesModified = this.attributesModified.filter(
          att => att.tenantAttributeId != classAttribute.tenantAttributeId
        );
      }
      if (attribute.attributeSelectedOptionIds.includes(-99)) {
        //Setting spot attribute to no value
        attribute.attributeSelectedOptionIds = [];
        attribute.hasNoValue = true;
      } else {
        attribute.hasNoValue = false;
      }

      this.editedAttribute = {};
      this.multiSelectedOptions = [];
      this.updated();
    },
    addAttribute() {
      this.addMode = true;
    },
    newAttributeCancel() {
      this.addMode = false;
      this.clearNewAttribute();
    },
    async newAttributeAdd() {
      const isValid = await this.$refs.attributesInput.validate();
      if (!isValid) {
        return;
      }
      this.addMode = false;
      let attribute = {
        tenantAttributeId: this.newAttribute.id,
        value: this.newAttribute.value,
        attributeSelectedOptionIds: []
      };
      if (this.newAttribute.selectedOptionId > 0) {
        attribute.attributeSelectedOptionIds.push(
          this.newAttribute.selectedOptionId
        );
      }
      if (this.newAttribute.selectedOptionIds.length > 0) {
        this.newAttribute.selectedOptionIds.forEach(att => {
          attribute.attributeSelectedOptionIds.push(att.id);
        });
      }
      this.attributesModified.push(attribute);

      this.clearNewAttribute();
      this.updated();
    },
    getAttributeValueType(id) {
      const att = this.allAttributes.find(a => a.id == id);
      if (!att) {
        return null;
      }
      return att.globalAttribute.attributeValueType.id;
    },
    getRangeValidationString(id) {
      const att = this.allAttributes.find(a => a.id == id);
      if (!att) {
        return "";
      }
      if (!att.globalAttribute.maxValue) {
        return "";
      }
      const betweenString =
        "between:" +
        att.globalAttribute.minValue +
        "," +
        att.globalAttribute.maxValue;
      return betweenString;
    },
    getAttributeOptions(id) {
      const attribute = this.allAttributes.find(a => a.id == id);
      let options = [];
      options.push({ id: -99, value: "No Value" });
      attribute.attributeOptions.forEach(o => {
        let option = {
          id: o.id,
          value: o.value
        };
        options.push(option);
      });
      return options;
    },
    getSelectedValue(id) {
      return this.getSelectedValues(id)[0];
    },
    getSelectedValues(id) {
      const attribute = this.classAttributes.find(
        ca => ca.tenantAttributeId == id
      );
      return attribute.attributeSelectedOptionIds;
    },
    clearNewAttribute() {
      this.newAttribute = {
        id: "",
        selectedOptionIds: [],
        selectedOptionId: "",
        value: ""
      };
    },
    getAttributeFieldValue(attributeValue, attribute) {
      let fieldValue = "";
      if (!attributeValue) {
        return fieldValue;
      }
      if (attributeValue.hasNoValue === true) {
        return "-99";
      }

      const valueType = attribute.globalAttribute.attributeValueType.id;

      if (valueType == 1 || valueType == 2) {
        attributeValue.attributeSelectedOptionIds.forEach(optionId => {
          if (fieldValue != "") {
            fieldValue += ", ";
          }
          const attributeOption = attribute.attributeOptions.find(
            option => option.id == optionId
          );
          if (attributeOption) {
            fieldValue += attributeOption.value;
          }
        });
      } else {
        fieldValue = attributeValue.value;
      }
      return fieldValue;
    },
    clone(obj) {
      return JSON.parse(JSON.stringify(obj));
    }
  },
  watch: {
    classAttributes() {
      if (!this.spotAttributes) {
        this.attributesModified = JSON.parse(
          JSON.stringify(this.classAttributes)
        );
      }
    },
    spotAttributes() {
      this.attributesModified = JSON.parse(JSON.stringify(this.spotAttributes));
    }
  },
  mounted() {
    if (!this.spotAttributes) {
      if (this.classAttributes) {
        this.attributesModified = JSON.parse(
          JSON.stringify(this.classAttributes)
        );
      }
    } else {
      this.attributesModified = JSON.parse(JSON.stringify(this.spotAttributes));
    }
  }
};
</script>
<style scoped>
.differentValues {
  text-decoration: line-through;
  text-decoration-thickness: 15%;
}
</style>
