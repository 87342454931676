import AdminOperationalSeasonApi from "@/generatedapiclients/src/api/AdminOperationalSeasonApi.js";
import AdminPricingManagementApi from "@/generatedapiclients/src/api/AdminPricingManagementApi.js";
import AdminTenantApi from "@/generatedapiclients/src/api/AdminTenantApi.js";

export default class AdminTenantService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._adminOperationalSeasonApi = new AdminOperationalSeasonApi();
    this._adminPricingManagementApi = new AdminPricingManagementApi();
    this._adminTenantApi = new AdminTenantApi();
  }

  async getOperationalSeasonTypes() {
    const response = await this._adminOperationalSeasonApi.v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonTypesGet(
      this._tenantId
    );
    return response;
  }
  async getOperationalSeasons() {
    const response = await this._adminOperationalSeasonApi.v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonsGet(
      this._tenantId
    );
    return response;
  }
  async saveOperationalSeason(season) {
    let response = null;
    if (season.id) {
      response = await this._adminOperationalSeasonApi.v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonPut(
        this._tenantId,
        {
          operationalSeason: {
            ...season
          }
        }
      );
    } else {
      response = await this._adminOperationalSeasonApi.v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonPost(
        this._tenantId,
        {
          operationalSeason: {
            ...season
          }
        }
      );
    }
    return response;
  }
  async getDateBlockTypes() {
    const response = await this._adminPricingManagementApi.v1TenantTenantIdAdminAdminPricingManagementDateBlockTypesGet(
      this._tenantId
    );
    return response;
  }
  async getDateBlockDays() {
    const response = await this._adminPricingManagementApi.v1TenantTenantIdAdminAdminPricingManagementDateBlockDaysGet(
      this._tenantId
    );
    return response;
  }
  async getDateBlocks() {
    const response = await this._adminPricingManagementApi.v1TenantTenantIdAdminAdminPricingManagementDateBlocksGet(
      this._tenantId
    );
    return response;
  }
  async deleteDateBlock(id) {
    const response = await this._adminPricingManagementApi.v1TenantTenantIdAdminAdminPricingManagementDateBlockIdDelete(
      id,
      this._tenantId
    );
    return response;
  }
  async saveDateBlock(dateBlock) {
    let response = null;
    if (dateBlock.id) {
      response = await this._adminPricingManagementApi.v1TenantTenantIdAdminAdminPricingManagementDateBlockPut(
        this._tenantId,
        {
          dateBlock
        }
      );
    } else {
      response = await this._adminPricingManagementApi.v1TenantTenantIdAdminAdminPricingManagementDateBlockPost(
        this._tenantId,
        {
          dateBlock
        }
      );
    }
    return response;
  }
  async getTenant() {
    const response = await this._adminTenantApi.v1TenantTenantIdAdminAdminTenantTenantGet(
      this._tenantId
    );
    return response;
  }
  async updateTenant(tenant) {
    const response = await this._adminTenantApi.v1TenantTenantIdAdminAdminTenantTenantPut(
      this._tenantId,
      {
        tenant
      }
    );
    return response;
  }
  async getAttributes(attributeRequest) {
    const response = await this._adminTenantApi.v1TenantTenantIdAdminAdminTenantAttributesGet(
      this._tenantId,
      {
        attributeRequest
      }
    );
    return response;
  }
  async getTemplates() {
    const response = await this._adminTenantApi.v1TenantTenantIdAdminAdminTenantCommunicationtemplatesGet(
      this._tenantId
    );
    return response;
  }
  async getPricePointComponentTypes() {
    const response = await this._adminPricingManagementApi.v1TenantTenantIdAdminAdminPricingManagementPricePointComponentTypesGet(
      this._tenantId
    );
    return response;
  }
}
