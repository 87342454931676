<template>
  <div v-if="attributes">
    <div class="row">
      <div class="col-6 mb-3">
        <TextInput
          name="Reference ID"
          id="refId"
          :disabled="true"
          v-model="referenceId"
        />
      </div>
      <div class="col-6 mb-3">
        <TextInput
          rules="required"
          name="Product Class Name"
          id="name"
          placeholder="Enter class name"
          :disabled="view"
          v-model="productModified.productClassName"
          @input="updated"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <TextInput
          rules="alpha_num_special"
          name="Product Class Description"
          id="description"
          placeholder="Enter description"
          v-model="productModified.productClassDescription"
          :disabled="view"
          rows="5"
          @input="updated"
        />
        <small class="form-text text-muted mb-2"
          >This description is shown on class indicator hover for a spot
          tile.</small
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <TextInput
          rules="alpha_num_special|max:5000"
          name="Product Class Internal Description"
          id="description"
          placeholder="Enter description"
          v-model="productModified.productClassInternalDescription"
          :disabled="view"
          rows="5"
          @input="updated"
        />
        <small class="form-text text-muted mb-2"
          >Internal description of product class for internal use only.</small
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ValidationProvider
          rules="max:1500"
          name="Product Class Alert Message"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="alertText"
            >Product Class Alert Message</label
          >
          <ckeditor
            :config="editorConfig"
            v-model="productModified.message"
            v-bind="ariaInput"
            id="alertText"
            @input="updated"
            :read-only="view"
          />
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2">
          Text will be displayed on customer site for sites of this Product
          class. No impact on the Admin site.
        </small>
      </div>
    </div>
    <div class="row" v-if="productModified.message">
      <div class="col-6 mb-3 mt-2">
        <div class="d-flex align-items-center">
          <ValidationProvider
            rules="required_if:applyMessageToEmail,false,null,undefined"
            name="Apply To Website"
            vid="applyMessageToWebsite"
            v-slot="{ errors, ariaInput, ariaMsg }"
          >
            <div>
              <input
                type="checkbox"
                class="mr-2"
                id="applyMessageToWebsite"
                v-model="productModified.applyMessageToWebsite"
                v-bind="ariaInput"
                @change="updated"
                :disabled="view || !productModified.message"
              />
              <label class="form-check-label" for="applyMessageToWebsite"
                >Apply To Website</label
              >
              <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                <li
                  v-for="(error, index) in errors"
                  :key="index"
                  class="error"
                  v-bind="ariaMsg"
                >
                  {{ error }}
                </li>
              </ul>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="col-6 mb-3 mt-2">
        <div class="d-flex align-items-center">
          <ValidationProvider
            rules="required_if:applyMessageToWebsite,false,null,undefined"
            name="Apply To Email"
            vid="applyMessageToEmail"
            v-slot="{ errors, ariaInput, ariaMsg }"
          >
            <div class="mr-2">
              <input
                type="checkbox"
                class="mr-2"
                id="applyMessageToEmail"
                v-model="productModified.applyMessageToEmail"
                v-bind="ariaInput"
                @change="updated"
                :disabled="view || !productModified.message"
              />
              <label class="form-check-label" for="applyMessageToEmail"
                >Apply To Email</label
              >
              <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                <li
                  v-for="(error, index) in errors"
                  :key="index"
                  class="error"
                  v-bind="ariaMsg"
                >
                  {{ error }}
                </li>
              </ul>
            </div>
          </ValidationProvider>
        </div>
        <div>
          <b-form-group
            v-slot="{ ariaDescribedby }"
            v-if="productModified.applyMessageToEmail"
          >
            <b-form-radio
              v-model="productModified.showMessageAtTop"
              :disabled="view"
              :aria-describedby="ariaDescribedby"
              name="showMessageAtTop"
              :value="true"
              >Position at Top of Email Template</b-form-radio
            >
            <b-form-radio
              v-model="productModified.showMessageAtTop"
              :disabled="view"
              :aria-describedby="ariaDescribedby"
              name="showMessageAtTop"
              :value="false"
              >Position at Bottom of Email Template</b-form-radio
            >
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <ValidationProvider
          name="Product Type"
          rules="required"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="productType"
          >
            Product Type
            <span class="error">*</span>
          </label>
          <select
            class="form-control form-control-lg"
            id="productType"
            v-model="productModified.productTypeId"
            v-bind="ariaInput"
            :disabled="view"
            @change="updated"
          >
            <option
              v-for="item in productTypes"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2"
          >The product type applicable to this product class.</small
        >
      </div>
      <div class="col-6">
        <ValidationProvider
          name="Spot Types"
          rules="required"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="spotTypes"
          >
            Spot Type(s)
            <span class="error">*</span>
          </label>
          <multiselect
            v-model="selectedSpotTypes"
            label="name"
            track-by="id"
            :multiple="true"
            id="spotTypes"
            ref="spotTypes"
            :clear-on-select="false"
            :preserve-search="true"
            open-direction="bottom"
            placeholder="None"
            :options="spotTypes"
            :disabled="view"
            @input="spotTypesUpdated"
            v-bind="ariaInput"
          >
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="multiselect__tag" :key="option.id">
                <span>{{ option.name }}</span>
                <i
                  tabindex="1"
                  @keypress.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon"
                ></i>
              </span>
            </template>
          </multiselect>

          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2"
          >The spot type(s) applicable to this product class.</small
        >
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label for="attributeIcons">Product Class Icon(s)</label>

        <multiselect
          v-model="selectedAttributeIcons"
          label="name"
          track-by="id"
          :multiple="true"
          id="attributeIcons"
          ref="attributeIcons"
          :clear-on-select="false"
          open-direction="bottom"
          placeholder="Select..."
          :options="iconAttributes"
          :disabled="view"
          @input="attributeIconsUpdated"
        >
          <template slot="tag" slot-scope="{ option, remove }">
            <span class="multiselect__tag" :key="option.id">
              <i :class="option.icon"></i>
              {{ option.name }}
              <span @click="remove(option)">
                <i
                  class="multiselect__tag-icon"
                  aria-hidden="true"
                  tabindex="-1"
                ></i>
              </span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <i :class="props.option.icon"></i>
            {{ props.option.name }}
          </template>
        </multiselect>
        <small class="form-text text-muted mb-2"
          >The icons to show on class filters.</small
        >
      </div>
      <div class="col-6">
        <ValidationProvider
          name="Equipment Types"
          rules="required"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="equipmentTypes"
          >
            Equipment Type(s)
            <span class="error">*</span>
          </label>
          <multiselect
            v-model="selectedEquipmentTypes"
            label="name"
            track-by="id"
            :multiple="true"
            id="equipmentTypes"
            ref="equipmentTypes"
            :clear-on-select="false"
            :preserve-search="true"
            open-direction="bottom"
            placeholder="None"
            :options="equipmentTypes"
            :disabled="view"
            @input="equipmentTypesUpdated"
            v-bind="ariaInput"
          >
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="multiselect__tag" :key="option.id">
                <span>{{ option.name }}</span>
                <i
                  tabindex="1"
                  @keypress.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon"
                ></i>
              </span>
            </template>
          </multiselect>

          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2"
          >The equipment allowed for this product class.</small
        >
      </div>
    </div>
  </div>
</template>

<script>
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import { ValidationProvider } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import Multiselect from "vue-multiselect";
import ckconfig from "@/helpers/ckeditorConfig.js";

export default {
  name: "AdminproductFields",
  props: {
    product: Object,
    productTypes: Array,
    view: { type: Boolean, default: false },
    attributes: { type: Array }
  },
  components: {
    ValidationProvider,
    TextInput,
    Multiselect
  },
  data() {
    return {
      productModified: {},
      selectedSpotTypes: [],
      spotTypes: [],
      selectedAttributeIcons: [],
      equipmentTypes: [],
      selectedEquipmentTypes: [],
      editorConfig: ckconfig
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    classAttributes() {
      return [];
    },
    iconAttributes() {
      return this.attributes
        .filter(att => att.globalAttribute.icon)
        .map(att => {
          return {
            id: att.id,
            name: att.globalAttribute.name,
            icon: att.globalAttribute.icon
          };
        });
    },
    referenceId() {
      return this.productModified.productClassId
        ? `P${(this.productModified.productClassId + "").padStart(3, "0")}`
        : "";
    }
  },
  methods: {
    spotTypesUpdated() {
      this.productModified.spotTypeIds = this.selectedSpotTypes.map(
        st => st.id
      );
      this.updated();
    },
    attributeIconsUpdated() {
      this.productModified.attributeIconIds = this.selectedAttributeIcons.map(
        i => i.id
      );
      this.updated();
    },
    equipmentTypesUpdated() {
      this.productModified.equipmentTypeIds = this.selectedEquipmentTypes.map(
        st => st.id
      );
      this.updated();
    },
    updated() {
      this.$emit("updated", this.productModified);
    },
    getSpotTypes() {
      const lookupService = new AdminLookupService(this.tenantId);
      lookupService.getAllSpotTypes().then(response => {
        this.spotTypes = response.spotTypes;
      });
    },
    getEquipmentTypes() {
      const lookupService = new AdminLookupService(this.tenantId);
      lookupService.getEquipmentTypes().then(response => {
        this.equipmentTypes = response.data;
      });
    }
  },
  mounted() {
    this.getSpotTypes();
    this.getEquipmentTypes();
    this.$refs.spotTypes.$refs.search.setAttribute("autocomplete", "off");
    this.$refs.attributeIcons.$refs.search.setAttribute("autocomplete", "off");
    this.$refs.equipmentTypes.$refs.search.setAttribute("autocomplete", "off");
  },
  watch: {
    product() {
      this.productModified = JSON.parse(JSON.stringify(this.product));
      this.selectedSpotTypes = this.spotTypes.filter(spotType => {
        return this.productModified.spotTypeIds.includes(spotType.id);
      });
      this.selectedAttributeIcons = this.iconAttributes.filter(icon => {
        return this.productModified.attributeIconIds.includes(icon.id);
      });
      this.selectedEquipmentTypes = this.equipmentTypes.filter(type => {
        return this.productModified.equipmentTypeIds.includes(type.id);
      });
    },
    spotTypes() {
      if (!this.productModified.spotTypeIds) {
        return;
      }
      this.selectedSpotTypes = this.spotTypes.filter(spotType => {
        return this.productModified.spotTypeIds.includes(spotType.id);
      });
    },
    equipmentTypes() {
      if (!this.productModified.equipmentTypeIds) {
        return;
      }
      this.selectedEquipmentTypes = this.equipmentTypes.filter(type => {
        return this.productModified.equipmentTypeIds.includes(type.id);
      });
    },
    iconAttributes() {
      if (!this.productModified.attributeIconIds) {
        return;
      }

      this.selectedAttributeIcons = this.iconAttributes.filter(icon => {
        return this.productModified.attributeIconIds.includes(icon.id);
      });
    }
  }
};
</script>
