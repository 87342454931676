<template>
  <div>
    <div class="pb-2">
      <h3>Product Class Management</h3>
      <hr />
    </div>
    <div class="card">
      <div class="card-body">
        <ValidationObserver ref="discountClassForm">
          <form @submit.prevent="onSubmit">
            <AdminProductClassFields
              :product="product"
              :productTypes="productTypes"
              :attributes="allAttributes"
              :view="true"
            />
            <AdminProductClassAttributes
              :classAttributes="product.attributes"
              :allAttributes="allAttributes"
              :view="true"
            />
            <button
              class="btn btn-lg btn-primary mt-4 mr-2"
              @click="cancel"
              type="button"
            >
              Cancel
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import AdminProductManagementService from "@/services/admin/AdminProductManagementService.js";
import AdminTenantService from "@/services/admin/AdminTenantService.js";
import { ValidationObserver } from "vee-validate";
import AdminProductClassFields from "@/components/admin/productclass/AdminProductClassFields.vue";
import AdminProductClassAttributes from "@/components/admin/productclass/AdminProductClassAttributes.vue";

export default {
  name: "AdminProductClassView",
  title: "Admin - Product Class View",
  data() {
    return {
      product: {},
      productTypes: [],
      spotTypes: [],
      allAttributes: []
    };
  },
  components: {
    ValidationObserver,
    AdminProductClassFields,
    AdminProductClassAttributes
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    getProduct() {
      const productId = this.$route.params.productId;
      const service = new AdminProductManagementService(this.tenantId);
      service.getProduct(productId).then(response => {
        this.product = response;
      });
    },
    getProductTypes() {
      const service = new AdminProductManagementService(this.tenantId);
      service.getAllProductTypes().then(response => {
        this.productTypes = response;
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    getAttributes() {
      const service = new AdminTenantService(this.tenantId);
      service.getAttributes().then(response => {
        this.allAttributes = response.data;
      });
    }
  },
  created() {
    this.getProduct();
    this.getProductTypes();
    this.getAttributes();
  }
};
</script>
