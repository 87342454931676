/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import OperationalSeason from '../model/OperationalSeason';
import OperationalSeasonIEnumerableResponse from '../model/OperationalSeasonIEnumerableResponse';
import OperationalSeasonResponse from '../model/OperationalSeasonResponse';
import OperationalSeasonTypeIEnumerableResponse from '../model/OperationalSeasonTypeIEnumerableResponse';

/**
* AdminOperationalSeason service.
* @module api/AdminOperationalSeasonApi
* @version v1
*/
export default class AdminOperationalSeasonApi {

    /**
    * Constructs a new AdminOperationalSeasonApi. 
    * @alias module:api/AdminOperationalSeasonApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/OperationalSeason} opts.operationalSeason 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OperationalSeasonResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['operationalSeason'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = OperationalSeasonResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminOperationalSeason/operationalSeason', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/OperationalSeason} opts.operationalSeason 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OperationalSeasonResponse}
     */
    v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/OperationalSeason} opts.operationalSeason 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OperationalSeasonResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['operationalSeason'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = OperationalSeasonResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminOperationalSeason/operationalSeason', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/OperationalSeason} opts.operationalSeason 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OperationalSeasonResponse}
     */
    v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OperationalSeasonTypeIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonTypesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonTypesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = OperationalSeasonTypeIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminOperationalSeason/operationalSeasonTypes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OperationalSeasonTypeIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonTypesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonTypesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OperationalSeasonIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonsGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = OperationalSeasonIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminOperationalSeason/operationalSeasons', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OperationalSeasonIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonsGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminOperationalSeasonOperationalSeasonsGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
