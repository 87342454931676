/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The OperationalSeasonSegment model module.
 * @module model/OperationalSeasonSegment
 * @version v1
 */
class OperationalSeasonSegment {
    /**
     * Constructs a new <code>OperationalSeasonSegment</code>.
     * @alias module:model/OperationalSeasonSegment
     */
    constructor() { 
        
        OperationalSeasonSegment.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>OperationalSeasonSegment</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OperationalSeasonSegment} obj Optional instance to populate.
     * @return {module:model/OperationalSeasonSegment} The populated <code>OperationalSeasonSegment</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OperationalSeasonSegment();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('operationalSeasonTypeId')) {
                obj['operationalSeasonTypeId'] = ApiClient.convertToType(data['operationalSeasonTypeId'], 'Number');
            }
            if (data.hasOwnProperty('operationalSeasonId')) {
                obj['operationalSeasonId'] = ApiClient.convertToType(data['operationalSeasonId'], 'Number');
            }
            if (data.hasOwnProperty('displayOrder')) {
                obj['displayOrder'] = ApiClient.convertToType(data['displayOrder'], 'Number');
            }
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'String');
            }
            if (data.hasOwnProperty('endDate')) {
                obj['endDate'] = ApiClient.convertToType(data['endDate'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
OperationalSeasonSegment.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
OperationalSeasonSegment.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
OperationalSeasonSegment.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
OperationalSeasonSegment.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
OperationalSeasonSegment.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
OperationalSeasonSegment.prototype['tenantId'] = undefined;

/**
 * @member {Number} operationalSeasonTypeId
 */
OperationalSeasonSegment.prototype['operationalSeasonTypeId'] = undefined;

/**
 * @member {Number} operationalSeasonId
 */
OperationalSeasonSegment.prototype['operationalSeasonId'] = undefined;

/**
 * @member {Number} displayOrder
 */
OperationalSeasonSegment.prototype['displayOrder'] = undefined;

/**
 * @member {String} startDate
 */
OperationalSeasonSegment.prototype['startDate'] = undefined;

/**
 * @member {String} endDate
 */
OperationalSeasonSegment.prototype['endDate'] = undefined;






export default OperationalSeasonSegment;

